import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import siteConfig from '../../data/SiteConfig';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className="my-8 mb-16">
  <div className="mx-auto w-48 mt-4">
    <img src="/img/paulswail_2021-09_a-square.jpeg" alt="Paul Swail" className="rounded-full" />
  </div>
  <h1 className="text-center font-extrabold">Hi, I’m Paul Swail.</h1>
  <h2 className="text-center italic font-medium mt-0">
    I guide small teams building serverless apps on AWS.
  </h2>
    </div>
    <h2 className="text-center">New to Serverless?</h2>
If you’ve heard a lot about serverless but not sure about how to get started with
it, my free Transition to Serverless email course is a great place to start. It’s
a 5-day course to teach you both the why and how of adopting a serverless-first approach
to building applications on AWS. You can jump in here:
    <p className="text-center my-4">
  <a href="/transition-to-serverless" className="inline-block btn px-4 py-3 bg-primary-700 hover:bg-primary-600 text-white hover:text-white rounded-md mb-2 font-sans leading-none">
    Transition to Serverless » <br />
    <span className="text-xs uppercase">Free Course</span>
  </a>
    </p>
    <hr className="border-dotted mt-12 mb-8" />
    <h2 className="text-center">Popular Articles</h2>I write regularly about serverless.
Here’s a list of my most popular articles:
    <h3>{`Strategy & Engineering Approach`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/beware-best-practice/"
        }}>{`Beware “Best Practice”`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/paradigm-shifts/"
        }}>{`Paradigm shifts`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/optimise-right-thing-right-time/"
        }}>{`Optimising for the right thing at the right time`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/serverless-value-proposition-for-dev-agencies/"
        }}>{`The serverless value proposition for development agencies`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/iac-linchpin/"
        }}>{`Infrastructure-as-Code is the linchpin practice for successful serverless teams`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/testing-tradeoff-triangle/"
        }}>{`The testing trade-off triangle`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/integration-e-2-e-tests/"
        }}>{`Integration and E2E tests are the primary confidence drivers for serverless apps`}</a></li>
    </ul>
    <h3>{`Technical/How-tos`}</h3>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/dynamodb-modelling-single-vs-multi-table/"
        }}>{`Comparing multi and single table approaches to designing a DynamoDB data model`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/serverless-photo-upload-api/"
        }}>{`How to build a serverless photo upload service with API Gateway`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/aws-async-message-services/"
        }}>{`7 ways to do async message processing in AWS`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/serverless-cicd-pipeline-testing/"
        }}>{`The Simple Guide to Testing within your Serverless CI/CD Pipelines`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://serverlessfirst.com/serverless-migration-journal/"
        }}>{`Migrating a Monolithic SaaS App to Serverless — A Decision Journal (6-part series)`}</a></li>
    </ul>
    <h2 className="text-center">Other Stuff</h2>
    <ul>
  <li>
    <a href="https://serverlessfirst.com/consulting/">
      My paid consulting services
    </a>
  </li>
  <li>
    <a href="https://notes.paulswail.com">My public notes vault</a>
  </li>
  <li>
    <a href="https://serverlessfirst.com/newsletter">My email newsletter</a>
  </li>
  <li>
    <a href="https://serverlessfirst.com/articles">
      All my blog articles on serverless
    </a>
  </li>
  <li>
    <a href="https://serverlessfirst.com/testimonials/">
      Testimonials from clients, student and readers
    </a>
  </li>
  <li>
    <a href="https://serverlessfirst.com/workshops/testing">
      Serverless Testing Workshop
    </a>
  </li>
  <li>
    <a href="https://serverlessfirst.com/contact">Contact me</a>
  </li>
  {
        /* <li>
         <a href={siteConfig.introCall.bookingUrl}>
           Free intro call
         </a>
        </li> */
      }
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      