import React from 'react';
import Home from '../templates/home.mdx';
import Layout from '../templates/layout';
import MetaTags from '../components/MetaTags';

const HomePage = () => (
  <Layout>
    <MetaTags
      title="Serverless Architecture Consulting"
      description="AWS and serverless application consulting services"
      path="/"
    />
    <article>
      <section className="max-w-2xl mx-auto px-4">
        <Home />
      </section>
    </article>
  </Layout>
);

export default HomePage;
